'use strict';

var isRelative = require('is-relative');
var isWindows = require('is-windows');

/**
 * Expose `isAbsolute`
 */

module.exports = isAbsolute;

/**
 * Returns true if a file path is absolute.
 *
 * @param  {String} `fp`
 * @return {Boolean}
 */

function isAbsolute(fp) {
  if (typeof fp !== 'string') {
    throw new TypeError('isAbsolute expects a string.');
  }
  return isWindows() ? isAbsolute.win32(fp) : isAbsolute.posix(fp);
}

/**
 * Test posix paths.
 */

isAbsolute.posix = function posixPath(fp) {
  return fp.charAt(0) === '/';
};

/**
 * Test windows paths.
 */

isAbsolute.win32 = function win32(fp) {
  if (/[a-z]/i.test(fp.charAt(0)) && fp.charAt(1) === ':' && fp.charAt(2) === '\\') {
    return true;
  }
  // Microsoft Azure absolute filepath
  if (fp.slice(0, 2) === '\\\\') {
    return true;
  }
  return !isRelative(fp);
};
